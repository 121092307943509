import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import { injectIntl, IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import Img from 'gatsby-image'

import { Navigation } from '.'
import { Language } from '.'

import FundoHeader from '../../images/cover.jpg'

// Styles
import '../../styles/app.css'
/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const languageName = {
    "pt-br": "Português",
    en: "English"
  }

const DefaultLayout = ({ data, children, bodyClass, isHome, title, meta, intl }) => {
    const instagramUrl = `https://instagram.com/luperciomanoelesouza`
    const whatsappUrl = `https://api.whatsapp.com/send?phone=5541996475657&text=Ol%C3%A1%2C%20quero%20saber%20mais.`
    const mailUrl = `mailto:lupercio@lupercio.com.br`

    let menu = new Array({ label: `Sobre`, url: `/sobre` }, { label: `Obras de Arte`, url: `/obras-de-arte` }, { label: `Interiores`, url: `/interiores` }, { label: `Eventos`, url: `/eventos` })
    let menuEng = new Array({ label: `About`, url: `/en/sobre` }, { label: `Art`, url: `/en/obras-de-arte` }, { label: `Interior Design`, url: `/en/interiores` }, { label: `Events`, url: `/en/eventos` })
    
    return (
        <>
            <Helmet>
                <html lang="pt-BR" />
                <title>{title?title:'LUPERCIO Manoel e Souza - Arte & Decoração'}</title>
                <meta name="description" content={meta?meta:'LUPERCIO Manoel e Souza - Artista Plástico e Decorador'}></meta>
                <body className={bodyClass} />
            </Helmet>

            <div className="viewport">

                <div className="viewport-top">
                    {/* The main header section on top of the screen */}
                    <header className="site-head" style={isHome?{backgroundImage: `url(${FundoHeader})`, backgroundColor: `black`}:null}>
                        <div className="container">
                            <div className="site-nav-left flex justify-between">
                                <Link to={`/`+intl.locale+`/`} style={{height: `25px`}}>
                                    <Img fixed={data.logo.childImageSharp.fixed} alt="Lupercio Manoel e Souza" width={300} height={21} style={{height: `21px`}}/>
                                </Link>
                                
                                <div className="flex justify-end">                                   
                                    <a href={ instagramUrl } className="transition duration-300 ease-in-out transform hover:scale-125" target="_blank" rel="noopener noreferrer"><img style={{width: `28px`, height: `28px`}} src="/images/icons/instagram.svg" alt="Instagram" /></a>&nbsp;&nbsp;                                  
                                    <a href={ whatsappUrl } className="transition duration-300 ease-in-out transform hover:scale-125" target="_blank" rel="noopener noreferrer"><img style={{width: `28px`, height: `28px`}} src="/images/icons/whatsapp.svg" alt="WhatsApp" /></a>&nbsp;&nbsp;             
                                    <a href={ mailUrl } className="transition duration-300 ease-in-out transform hover:scale-125" target="_blank" rel="noopener noreferrer"><img style={{width: `28px`, height: `28px`, marginTop: `-1px`}} src="/images/icons/mail.svg" alt="WhatsApp" /></a>                                                                        
                                </div>
                            </div>
                            
     
                            { 
                            isHome ?
                                <div className="site-banner">
                                    <h1 className="site-banner-title" style={{whiteSpace: `nowrap`}}>{intl.formatMessage({ id: "title" })}</h1>
                                </div> 
                            :
                                null
                            }

                            <nav className="site-nav">
                                <div className="site-nav-left">
                                    
                                        <Navigation data={intl.locale=="en"?menuEng:menu} navClass="site-nav-item transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110" />
                                    
                                </div>
                                <div className="flex justify-end">
                                    <IntlContextConsumer>
                                        {({ languages, language: currentLocale }) =>
                                        languages.map(language => (
                                            <a
                                            key={language}
                                            onClick={() => changeLocale(language)}
                                            style={{
                                                color: currentLocale === language ? `yellow` : `white`,
                                                marginLeft: 10,
                                                textDecoration: `underline`,
                                                cursor: `pointer`,
                                                fontSize: `12px`,
                                                fontWeight: 500,
                                            }}
                                            >
                                              {languageName[language]}
                                            </a>
                                        ))
                                        }
                                    </IntlContextConsumer>
                            </div>
                            </nav>

                        </div>
                    </header>

                    <main className="site-main">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                <Link to="/"><b>Lupercio Manoel e Souza</b> © 2020</Link>  |  {intl.formatMessage({ id: "FeitoPor" })} <a href="https://codebox.com.br" target="_blank" rel="noopener noreferrer">CodeBox</a>
                            </div>
                            <div className="site-foot-nav-right">
                                <Navigation data={intl.locale=="en"?menuEng:menu} navClass="site-foot-nav-item" />
                            </div>
                        </div>
                    </footer>

                </div>
            </div>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    title: PropTypes.string,
    meta:  PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
    }).isRequired,
    
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
        query {
         
          logo: file(
            relativePath: { eq: "logo.png" }
          ) {
            childImageSharp {
                fixed(width: 300, height: 21) {
                    ...GatsbyImageSharpFixed
                }
            }
          }
          
          
      
        }
      `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default injectIntl(DefaultLayoutSettingsQuery)